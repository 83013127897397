import '../sass/main.scss';

import LazyLoad from 'vanilla-lazyload';

var siteGlobal = {
  initReady: function () {
  },
  initLoad: function () {
    this.subMenuInit();
  	this.lazyLoad();
    this.menuInit();
    this.smoothScroll();
    this.homeEvidenzaGall();
    this.inputWrap();
    this.footerToggle();
    this.pageAccodion();
    this.accordionOpened();
  },
  initScroll: function(){
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true
    })
  },
  subMenuInit: function(){
    $('.menu-item-has-children > a').addClass('no-smoothState');

      $('.menu-item-has-children > a').on('click', function(event){
        event.preventDefault();
      });

      if ($(window).outerWidth(true) > 767) {
        $('.menu-item-has-children').on('mouseenter', function(event){
          $(this).find('.sub-menu').addClass('open');
        });
        $('.menu-item-has-children').on('mouseleave', function(event){
          $(this).find('.sub-menu').removeClass('open');
        });
      } else {
        $('.menu-item-has-children > a').on('click', function(event){
          event.preventDefault();
          $(this).siblings('.sub-menu').slideToggle(200);
        });

      }

  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header__nav').toggleClass('opened');
    })
  },
  homeEvidenzaGall: function(){
    if ($('.ev__gall').length) {
      $('.ev__gall').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        speed: 600,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3500,
        lazyLoad:'ondemand'
      });

      /* Cambio numero progressivo slide */
      $('.ev__gall').on('afterChange', function(event, slick, currentSlide, nextSlide){
        var i = (currentSlide ? currentSlide : 0) + 1;
        $('.slider_counter').html('<span>' + i + '</span>/' + slick.slideCount);
      });

    }
  },
  smoothScroll: function(){
    $(document).on('click', 'a[href^="#"]', function (event) {
      event.preventDefault();

      $('html, body').animate({
          scrollTop: 0
      }, 500);
    });
  },
  inputWrap: function(){    
    $('[name="privacy"]').each(function(){
      $(this).after('<span class="check_wrap"></span>');
    });
    $('.wpcf7 [type="submit"]').each(function(){
      $(this).wrap('<div class="submitWrap button"></div>');
    });
  },
  footerToggle: function(){
    if ($(window).outerWidth(true) < 767) {
      $('.footer__label').on('click',function(){
        var text = $(this).siblings('.footer__txtBlock');
        text.slideToggle(150);
        $(this).toggleClass('opened');
      })
    }
  },
  pageAccodion: function(){
    $('.acc__label').on('click', function(){
      var acc = $(this).siblings('.acc__text');
      $(acc).slideToggle(200);
      $(this).toggleClass('open');
    })
  },
  accordionOpened: function(){
    var hash = window.location.hash;
    if(hash) {
      var lab = $(hash).find('.acc__label');
      console.log($(lab));
      $(lab).trigger('click');
      $(lab).addClass('open');
      setTimeout(function(){      
        $('html, body').animate({
            scrollTop: $(hash).offset().top - 150
        }, 500);
      }, 300)

    } 
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  $(window).on('load', function() {
    $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })
}());